// Some refs to keep an eye on:
// * https://github.com/aws-samples/medical-transcription-analysis/ (https://sma.sandbox.eksoh.com/)
// * https://aws.amazon.com/blogs/machine-learning/build-an-appointment-scheduler-interface-integrated-with-meta-using-amazon-lex-and-amazon-connect/
// * https://github.com/aws-solutions/qnabot-on-aws
// * https://github.com/aws-samples/aws-lex-web-ui
// * https://github.com/aws-samples/simplifying-patient-care-with-a-custom-voice-assistant-using-amazon-lex-v2-blog
// * https://www.youtube.com/watch?v=A8J9t-UU3ug

import { useContext, useState, useRef, useEffect } from 'react';
import { Auth } from '@aws-amplify/auth';
//
import {
  User, Group, Locale, LocaleClass, RangeQuery, RangeOperators,
  AppmntGroups, TestAppmntTypes, TestTimeOffer, PaymentInfoInput,
  PreAppmtOperationInput, CancelAppmtInput, ListAppmtFilter, Appointment,
} from '@eksoh/flo/model'; // TODO @fp: that a big NO NO !!!
import { TZ_AMERICA_MONTREAL, montreal } from '@eksoh/shared/common';
import { ScheduleApiClient, AppmtsClient } from '@eksoh/flo/ui'; // TODO @fp: that a big NO NO !!!
import {
  authStore, nectoStore, eNectoActions, eNectoStatus, useLocation, conf, OffersList, ListedPreAppointment,
} from '../../..';

export const localeTestTimeOffer = {
  [TestTimeOffer.ANYTIME_10MINS]: { en: 'anytime - 10mins', fr: 'à toute heure - 10mins' },
  [TestTimeOffer.ANYTIME_AM_30MINS]: { en: 'anytime am - 30mins', fr: 'à toute heure avant-midi - 30 mins' },
  [TestTimeOffer.ANYTIME_EVE_15MINS]: { en: 'anytime evening - 15mins', fr: 'à toute heure soirée - 15mins' },
  [TestTimeOffer.MON_TUE_1400_1500_20MINS]: { en: 'mon or thu from 2pm & 3pm - 20mins', fr: 'lun ou jeu de 14h et 15h - 20mins' },
};

function getTwoDigits(num: number) {
  return ('0' + num).slice(-2);
}

export function getTimeFromTo(from: Date, to: Date) {
  return `${{ en: 'from', fr: 'de' }.fr} ${getTwoDigits(from.getHours())}:${getTwoDigits(from.getMinutes())} ${{ en: 'to', fr: 'à' }.fr} ${getTwoDigits(to.getHours())}:${getTwoDigits(to.getMinutes())}`;
}

export function idPreAppmt(pa: ListedPreAppointment, code: string, lng: number, lat: number) {
  return pa.code === code && pa.lng === lng && pa.lat === lat;
}

export function idPreAppmt2(pa: ListedPreAppointment, code: string, lng: string, lat: string) {
  return pa.code === code && `${pa.lng}` === lng && `${pa.lat}` === lat;
}

export interface useNectoProps {
  overrideGeoloc?: GeolocationCoordinates;
  ignorePreAppnts?: boolean;
}

export function useNecto({ overrideGeoloc, ignorePreAppnts }: useNectoProps) {
  const { authState } = useContext(authStore);
  const {
    nectoState, ready, reset, setGeoloc, setAppmtDetails, setAppmts, setPreAppmts,
    startPreAppmt, setUsers: ctxSetUSers, continuePreAppmt: ctxContinuePreAppmt,
    setAppmtType: ctxSetAppmtType, createAppmt: ctxCreateAppmt, dispatch: ctxDispatch,
  } = useContext(nectoStore);
  // geoloc
  const { position } = useLocation({});
  // be clients
  const appmtsClient = useRef<AppmtsClient>();
  const scheduleApiClient = useRef<ScheduleApiClient>();
  const [dailyTimeOffersUi, setDailyTimeOffersUi] = useState<OffersList>();
  // feebacks
  const [group, setGroup] = useState<AppmntGroups>();
  // potential context values
  const [paymentInfo, setPaymentInfo] = useState<PaymentInfoInput>();
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    // this is the only way I got navigation to work at mount. the doc says
    // otherwise but I never managed to get it to load the other screens. Looks
    // like navigation is not yet ready event when useFocusEffect is called...
    setMounted(true);
  }, []);

  useEffect(() => {
    if (!mounted) return;
    scheduleApiClient.current = new ScheduleApiClient(
      {
        graphqlUrl: conf.aws.aws_appsync_graphqlEndpoint || 'INIT_CONF_ERROR',
        region: conf.aws.aws_appsync_region,
      },
      authState.jwtToken
    );
    appmtsClient.current = new AppmtsClient(
      {
        graphqlUrl: conf.aws.aws_appsync_graphqlEndpoint || 'INIT_CONF_ERROR',
        region: conf.aws.aws_appsync_region,
      },
      authState.jwtToken
    );

    fetchAppmtDetails();
    if (!ignorePreAppnts) fetchPreAppmts();
    fetchAppmts();
    ready();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mounted]);

  useEffect(() => {
    // The busy below is a bit risky if the gap is big betweem the first to last
    // position update. Might miss the last one but it is not likely.
    if (position == null) return;
    if (nectoState.geoloc == null) setGeoloc(position);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [position]);

  // useEffect(() => { if ((nectoState.appmts?.length || 0) > 0) console.log('>>> nectoState.appmts:', nectoState.appmts); }, [nectoState.appmts]);
  // useEffect(() => { if ((nectoState.preAppmts?.length || 0) > 0) console.log('>>> nectoState.preAppmts:', nectoState.preAppmts); }, [nectoState.preAppmts]);
  // useEffect(() => { if (nectoState.newPreAppmt != null) console.log('>>> nectoState.newPreAppmt:', nectoState.newPreAppmt); }, [nectoState.newPreAppmt]);
  // useEffect(() => console.log('>>> dailyTimeOffersUi:', dailyTimeOffersUi), [dailyTimeOffersUi]);

  // Helpers

  function getAppmtLen() {
    return nectoState.newAppmtType != null ? nectoState.appmntDetails[nectoState.newAppmtType].len : 3;
  }

  async function fetchAppmtDetails() {
    // if (group == null) return;
    ctxDispatch({ type: eNectoActions.SET_STATUS, status: eNectoStatus.FETCHING_APPMNTS })
    const result = await appmtsClient.current?.getAppmntTypesDetails();
    // console.log('>>> appointment details:', result);
    if (result != null) setAppmtDetails(result);
    ctxDispatch({ type: eNectoActions.SET_STATUS, status: eNectoStatus.IDLE })
  }

  async function fetchPreAppmts() {
    ctxDispatch({ type: eNectoActions.SET_STATUS, status: eNectoStatus.FETCHING_PRE_APPMTS })
    const preAppmts = await appmtsClient.current?.listPreAppmts();
    setPreAppmts(preAppmts);
    ctxDispatch({ type: eNectoActions.SET_STATUS, status: eNectoStatus.IDLE })
  }

  async function fetchAppmts(isHistory?: boolean) {
    if (authState.curGroups == null) return;
    if (authState.curGroups.includes(Group.SUPER_ADMIN)) return;
    const isClient = authState.curGroups.includes(Group.CLIENT);
    // TODO: assume nurse if not client. to improve later
    ctxDispatch({ type: eNectoActions.SET_STATUS, status: eNectoStatus.FETCHING_APPMNTS });
    if (!isClient) {
      setAppmts(await appmtsClient.current?.listAppmtsProfessional({}));
    } else if (isHistory) {
      setAppmts((await appmtsClient.current?.listAppmtsHistory(
        undefined, 
        new Date(),
        ListAppmtFilter.INACTIVE
      )) as Appointment[]);
    } else {
      setAppmts(await appmtsClient.current?.listAppmts(
        undefined,
        new Date()
      ));
    }
    ctxDispatch({ type: eNectoActions.SET_STATUS, status: eNectoStatus.IDLE })
  }

  async function createPreAppmt(type: TestAppmntTypes) {
    if (!nectoState.isReady) return;
    ctxDispatch({ type: eNectoActions.SET_STATUS, status: eNectoStatus.CREATING_PRE_APPMT });
    const credentials = await Auth.currentCredentials();
    await startPreAppmt({
      appmtType: type,
      detail: {
        email: authState.user?.email || 'email_error',
        familyName: authState.user?.familyName || 'familyName_error',
        givenName: authState.user?.givenName || 'givenName_error',
        locale: authState.user?.locale || LocaleClass.fr_CA.getLocaleEnum(),
        phoneNumber: authState.user?.phoneNumber,
        identityId: credentials.identityId,
        idFlow: authState.user?.idFlow
      },
      lat: overrideGeoloc?.latitude || montreal.latitude,
      lng: overrideGeoloc?.longitude || montreal.longitude,
      zoneinfo: TZ_AMERICA_MONTREAL,
    });
    ctxDispatch({ type: eNectoActions.SET_STATUS, status: eNectoStatus.IDLE })
  }

  function continuePreAppmt(appmtType: TestAppmntTypes, preAppmt: ListedPreAppointment, override?: boolean) {
    const usedPa = override ? preAppmt : nectoState.preAppmts?.find(pa => idPreAppmt(pa, preAppmt.code, preAppmt.lng, preAppmt.lat));
    if (usedPa != null) ctxContinuePreAppmt(appmtType, usedPa);
  }

  async function updPreAppmt(input: PreAppmtOperationInput) {
    if (!nectoState.isReady) return;

    ctxDispatch({ type: eNectoActions.SET_STATUS, status: eNectoStatus.UPDATING_PRE_APPMT });
    const preAppmt = await appmtsClient.current?.preAppmtOperation(input);
    if (preAppmt != null) ctxDispatch({ type: eNectoActions.SET_PRE_APPMT, preAppmt});
    ctxDispatch({ type: eNectoActions.SET_STATUS, status: eNectoStatus.IDLE })
  }

  async function cancelPreAppmt(preAppmt?: ListedPreAppointment, username?: string) {
    if (!nectoState.isReady || preAppmt == null) return;

    ctxDispatch({ type: eNectoActions.SET_STATUS, status: eNectoStatus.DELETING_PRE_APPMT });
    const isAdmin = authState.curGroups?.includes(Group.SUPER_ADMIN) || false;
    if (isAdmin) {
      if (username == null) return; // TODO @fe: Add UI feedback
      await appmtsClient.current?.admDeletePreAppmt({
        lng: preAppmt.lng,
        lat: preAppmt.lat,
        code: preAppmt.code,
        storageIdentityId: preAppmt.detail.identityId,
        storageFolderKey: preAppmt.storageFolderKey,
        username
      });
    }
    else {
      await appmtsClient.current?.deletePreAppmt({
        lng: preAppmt.lng,
        lat: preAppmt.lat,
        code: preAppmt.code,
        identityId: preAppmt.detail.identityId,
        storageFolderKey: preAppmt.storageFolderKey
      });
    }
    ctxDispatch({ type: eNectoActions.SET_STATUS, status: eNectoStatus.IDLE });
    // TODO @fe: optimize without a be call if possible...
    clear();
  }

  function setAppmtType(appmtType?: TestAppmntTypes) {
    ctxSetAppmtType(appmtType);
  }

  async function createAppmt(selectedDate: Date) {
    if (!nectoState.isReady) return;

    ctxDispatch({ type: eNectoActions.SET_STATUS, status: eNectoStatus.CREATING_APPMT });
    const isAdmin = authState.curGroups?.includes(Group.SUPER_ADMIN) || false;
    if (selectedDate == null || nectoState.newAppmtType == null || authState.user == null) return; // TODO @fe: Add UI feedback
    const user = isAdmin
      ? nectoState.users != null ? nectoState.users[0] : undefined
      : authState.user;
    if (user == null) return; // TODO @fe: Add UI feedback

    const credentials = await Auth.currentCredentials();

    const data = {
      appmtType: nectoState.newAppmtType,
      // lng: nectoState.geoloc.coords.longitude,
      // lat: nectoState.geoloc.coords.latitude,
      lat: overrideGeoloc?.latitude || montreal.latitude,
      lng: overrideGeoloc?.longitude || montreal.longitude,
      start: selectedDate,
      detail: {
        givenName: user.givenName || 'Missing', // full name here instead of first name... meuh...
        familyName: user.familyName || 'Missing',
        email: user.email,
        phoneNumber: user.phoneNumber,
        locale: user.locale || Locale.FR_CA,
        idFlow: user.idFlow
        // notes,
      },
      paymentInfo: paymentInfo as PaymentInfoInput
    };

    if (isAdmin) await ctxCreateAppmt(data, user.username, credentials.identityId);
    else await ctxCreateAppmt(data);
    ctxDispatch({ type: eNectoActions.SET_STATUS, status: eNectoStatus.IDLE });
  }

  async function cancelAppmt(input: CancelAppmtInput, username?: string) {
    if (!nectoState.isReady) return;

    ctxDispatch({ type: eNectoActions.SET_STATUS, status: eNectoStatus.DELETING_APPMT });
    const isAdmin = authState.curGroups?.includes(Group.SUPER_ADMIN) || false;
    if (isAdmin) {
      if (username == null) return; // TODO @fe: Add UI feedback
      await appmtsClient.current?.admDeleteAppmt({ ...input, username });
    }
    else {
      await appmtsClient.current?.cancelAppmt(input);
    }
    ctxDispatch({ type: eNectoActions.SET_STATUS, status: eNectoStatus.IDLE });
    // TODO @fe: optimize without a be call if possible...
    clear();
  }

  function setUsers(users: User[]) {
    ctxSetUSers(users);
  }

  function fetchTimeOffer(apType: TestAppmntTypes, test: TestTimeOffer) {
    if (test === TestTimeOffer.ANYTIME_10MINS) {
      searchTimeOffers(apType, [
        { start5mins: 0, end5mins: 2015, operator: RangeOperators.BETWEEN },
      ]);
    }
    // else if (test === TestTimeOffer.ANYTIME_AM_30MINS) {
    //   searchTimeOffersTod(apType, TimesOfDay.AM);
    // }
    // else if (test === TestTimeOffer.ANYTIME_EVE_15MINS) {
    //   searchTimeOffersTod(apType, TimesOfDay.EVENING);
    // }
    // else if (test === TestTimeOffer.MON_TUE_1400_1500_20MINS) {
    //   searchTimeOffers(apType, [
    //     { start5mins: 456, end5mins: 467, operator: RangeOperators.INCLUDES },
    //     { start5mins: 1320, end5mins: 1331, operator: RangeOperators.INCLUDES },
    //   ]);
    // }
  }

  async function searchTimeOffers(appmtType: TestAppmntTypes, ranges: RangeQuery[]) {
    // if (nectoState.geoloc == null) return;
    ctxDispatch({ type: eNectoActions.SET_STATUS, status: eNectoStatus.FETCHING_TIMEOFFERS });
    const res = await scheduleApiClient.current?.searchTimeOffers({
      lat: overrideGeoloc?.latitude || montreal.latitude, // geoloc.coords.latitude,
      lng: overrideGeoloc?.longitude || montreal.longitude, // geoloc.coords.longitude,
      appmtType,
      ranges,
    });
    // console.log('>>> BE GET TIME OFFERS:', res)
    setDailyTimeOffersUi(res);
    ctxDispatch({ type: eNectoActions.SET_STATUS, status: eNectoStatus.IDLE });
  }

  // async function searchTimeOffersTod(appmtType: TestAppmntTypes, tod: TimesOfDay, mask?: number) {
  //   if (nectoState.geoloc == null) return;
  //   setFetchingTimeOffers(true);
  //   const res = await scheduleApiClient.current?.searchTimeOffersTod({
  //     appmtType,
  //     lat: overrideGeoloc?.latitude || montreal.latitude, // geoloc.coords.latitude,
  //     lng: overrideGeoloc?.longitude || montreal.longitude, // geoloc.coords.longitude,
  //     tod,
  //     mask,
  //   });
  //   // console.log('>>> BE GET TIME OFFERS TOD:', res)
  //   setDailyTimeOffersUi(res);
  //   setFetchingTimeOffers(false);
  // }

  function clearTimeOffers() {
    setDailyTimeOffersUi(undefined);
    // setUsers([]);
    if (nectoState.status === eNectoStatus.FETCHING_TIMEOFFERS) {
      ctxDispatch({ type: eNectoActions.SET_STATUS, status: eNectoStatus.IDLE });
    }
  }

  function clear() {
    clearTimeOffers();
    reset('all_wo_infos');
    if (!ignorePreAppnts) fetchPreAppmts();
    fetchAppmts();
  }

  return {
    nectoReady: nectoState.geoloc != null, clear,
    fetchAppmts,
    group, setGroup, createPreAppmt,
    continuePreAppmt, updPreAppmt, cancelPreAppmt,
    createAppmt, cancelAppmt,
    fetchTimeOffer, dailyTimeOffersUi, clearTimeOffers,
    // payment
    paymentInfo, setPaymentInfo,
    // admin
    setUsers, setAppmtType,
    // helpers
    getAppmtLen,
  };
}
