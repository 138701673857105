/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk, createSlice, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit';
// import generator from 'generate-password-ts';
// 
import {
  // OnboardingRawData, ListNurseOnboardingQuery, AppointmentsState as OnbState,
  Appointment, ListAppmtsQuery, Locale, TestAppmntTypes,
  AdmListAllPreAppmtQuery, AdmPreAppmtOperationInput, AppointmentState,
} from '@eksoh/flo/model';
import { montreal, TZ_AMERICA_MONTREAL } from '@eksoh/shared/common';
import { serializeAxiosError } from '../../redux/reducers/reducer.utils';
import { BeServices, IRootState } from '../../..';

// export const defaultInputUser: UserInput = {
//   givenName: '',
//   familyName: '',
//   email: '',
//   groups: [Group.INSURER],
//   locale: Locale.FR_CA,
//   gender: Gender.O,
//   birthdate: new Date((new Date()).setFullYear(new Date().getFullYear() - 20)), // 20 years ago
//   phoneNumber: '+15141231234',
//   middleName: '',∏
//   zoneinfo: 'America/Toronto',
//   products: [Product.INSURANCE],
//   address: {
//     postalCode: ''
//   }
// }

export const defaultDataItem: Appointment = {
  __typename: 'Appointment',
  username: '',
  professional: '',
  start: '',
  durationIso: '',
  end: '',
  detail: {
    givenName: '',
    familyName: '',
    email: '',
    phoneNumber: '',
    locale: Locale.FR_CA,
    zoneinfo: TZ_AMERICA_MONTREAL
  },
  storageFolderKey: '',
  lng: 0,
  lat: 0,
  code: '',
  createdAt: '',
  updatedAt: '',
  state: AppointmentState.ACTIVE_INITIAL,
  storageIdentityId: '',
  order: {
    total: 0,
    subtotal: 0,
    discount: 0,
    fedTax: 0,
    provTax: 0,
    items: [
      {
        code: '',
        price: 0,
        i18nKey: '',
        total: 0,
        quantity: 1
      },
    ],
  },
  pathParam: ''
};

// export const defaulCreatetUser: CreateUserInput = {
//   user: defaultInputUser,
//   pwdOptions: {
//     action: PwdAction.SET,
//     password: '',
//   },
//   userType: UserType.BROKER,
//   userBroker: {
//     licenseNo: 'idLicense',
//   },
// }

export type AppmtResultType = NonNullable<ListAppmtsQuery['listAppmts']>;
export type PreAppmtResultType = NonNullable<AdmListAllPreAppmtQuery['admListAllPreAppmt']>;
const initialState = {
  loading: false,
  errorMessage: null as any,
  items: [] as (AppmtResultType | PreAppmtResultType),
  dataItem: { ...defaultDataItem } as any,
  updating: false,
  updateSuccess: false,
  totalItems: 0,
};

// Async Actions

// export const getUsers = createAsyncThunk('userManagement/fetch_users',
//   async ({ page, size, sort }: IQueryParams) => {
//     // const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`;
//     const result = await BeServices.getInstance().user.listUsers();
//     return result;
//   }
// );

async function getAllAppmtsOfAllTypes(from?: Date, lat?: number, lng?: number) {
  return BeServices.getInstance().appmts.admListAppmts(
    lng || montreal.longitude,
    lat || montreal.latitude,
    undefined,
    from
  );
}

export interface listAppmtDataProps {
  usePreAppmt: boolean;
  from?: Date;
  lat?: number;
  lng?: number;
}

export const listAppmtData = createAsyncThunk(
  'appointments/fetch_data_list',
  async ({ usePreAppmt, from, lat, lng }: listAppmtDataProps) => {
    try {
      const result = usePreAppmt
        ? await BeServices.getInstance().appmts.admListAllPreAppmt()
        : await getAllAppmtsOfAllTypes(from, lat, lng);

      return result;
    }
    catch (error) {
      console.log('>>> Get appointments error:', error);
      return [];
    }
  }
);

export const getPreAppmt = createAsyncThunk(
  'appointments/fetch_preappmt',
  async (username: string, { getState }) => {
    // find first item with id and return it
    const state = getState() as IRootState;
    const result = state.onboarding.items?.find(i => i.username === username);
    // const result = await BeServices.getInstance().user.listNurseOnboarding({completedOnly: false});
    if (result == null) throw new Error('item not found');
    return result;
  }
);

export interface createAppmtProps {
  appmtType: TestAppmntTypes,
  lng: number,
  lat: number,
  start: Date,
  detail: {
    givenName: string;
    familyName: string;
    email: string;
    phoneNumber?: string;
    locale: Locale;
    idFlow: string;
  },
  paymentInfo: {
    billingAddress: {
      postalCode: string;
      country: string;       // TODO make payment addresse type with countryName conutryCode
    },
    card: {
      number: string;
      expiryMonth: string;
      expiryYear: string;
      name: string;
      cvd: string;
    }
  },
  storageIdentityId: string;
}

export interface admCreateAppmtProps extends createAppmtProps {
  username: string,
}

export const createAppmt = createAsyncThunk(
  'appointments/create_appmt',
  async ({ appmtType, lng, lat, start, detail, paymentInfo }: createAppmtProps) => {
    return await BeServices.getInstance().appmts.createAppmt({
      appmtType,
      lng,
      lat,
      start,
      detail,
      paymentInfo
    });
  },
  { serializeError: serializeAxiosError } // TODO @fp - FE WORK - handler les errors appsync eventuellement
);

export const admCreateAppmt = createAsyncThunk(
  'appointments/adm_create_appmt',
  async ({ appmtType, lng, lat, start, detail, username, paymentInfo, storageIdentityId }: admCreateAppmtProps) => {
    return await BeServices.getInstance().appmts.admCreateAppmt({
      username,
      appmtType,
      lng,
      lat,
      start,
      detail, 
      paymentInfo, 
      storageIdentityId
    });
  },
  { serializeError: serializeAxiosError } // TODO @fp - FE WORK - handler les errors appsync eventuellement
);

export interface adminUpdatePreAppmtProps {
  input: AdmPreAppmtOperationInput;
  from?: Date;
}

export const adminUpdatePreAppmt = createAsyncThunk(
  'userManagement/admin_update_preappmt',
  async ({ input, from }: adminUpdatePreAppmtProps, { dispatch }) => {
    const result = await BeServices.getInstance().appmts.admPreAppmtOperation(input);

    dispatch(listAppmtData({ usePreAppmt: true, from }));
    return result;
  },
  { serializeError: serializeAxiosError }
);

// export const deleteUser = createAsyncThunk(
//   'userManagement/delete_user',
//   async (id: string, { dispatch }) => {
//     const result = await BeServices.getInstance().user.deleteUser(id);
//     return result;
//   },
//   { serializeError: serializeAxiosError }
// );

export type AppointmentsState = Readonly<typeof initialState>;

export const AppointmentsSlice = createSlice({
  name: 'appointments',
  initialState: initialState as AppointmentsState,
  reducers: {
    reset() {
      return initialState;
    },
    resetSuccess(state) {
      return { ...state, updateSuccess: false };
    },
  },
  extraReducers(builder) {
    builder
      // .addCase(deleteUser.fulfilled, state => {
      //   state.updating = false;
      //   state.updateSuccess = true;
      //   state.user = { ...defaultInputUser } as User;
      // })
      .addMatcher(isFulfilled(getPreAppmt), (state, action) => {
        state.loading = false;
        state.dataItem = action.payload;
      })
      .addMatcher(isFulfilled(getPreAppmt, admCreateAppmt, createAppmt, adminUpdatePreAppmt), (state, action) => {
        state.loading = false;
        state.updateSuccess = true;
        state.dataItem = action.payload;
      })
      .addMatcher(isFulfilled(listAppmtData), (state, action) => {
        state.loading = false;
        state.items = action.payload || [];
        // state.totalItems = parseInt(action.payload.headers['x-total-count'], 10);
        state.totalItems = action.payload?.length || 0;
      })
      .addMatcher(isPending(listAppmtData, getPreAppmt), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(isPending(admCreateAppmt, createAppmt, adminUpdatePreAppmt), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(isRejected(listAppmtData, getPreAppmt, admCreateAppmt, createAppmt, adminUpdatePreAppmt), (state, action) => {
        state.loading = false;
        state.updating = false;
        state.updateSuccess = false;
        state.errorMessage = action.error.message;
      });
  },
});

export const { reset, resetSuccess } = AppointmentsSlice.actions;

// Reducer
export default AppointmentsSlice.reducer;
