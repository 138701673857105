/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, createContext, useReducer, Dispatch, useRef, useContext } from 'react';
import { Auth, SignUpParams, CognitoUser } from '@aws-amplify/auth';
import { Hub } from '@aws-amplify/core';
import i18n from 'i18next';
import { DateTime } from 'luxon';
//
import { AuthUser, Group, hasProperGroups, OnboardingData, OnboardingState, Gender, Locale, LocaleClass } from '@eksoh/flo/model'; // TODO @sb: that a big NO NO !!!
import { UserServiceClient, EmrClient, ScheduleApiClient, AppmtsClient, FormsClient } from '@eksoh/flo/ui'; // TODO @sb: that a big NO NO !!!
import { MoreInfoData } from '@eksoh/shared/eksoh-kit';
import { conf, globalStore } from '../../..';
import { COGNITO_GROUPS_CLAIMS, VerifiedAttributeType, awsPhoneNumber, TZ_AMERICA_MONTREAL } from '@eksoh/shared/common';
// INTEG - delete enum below once from BE
export interface ChatInfo {
  myChatUserId: string;
  myUsername: string;
}

class InternalBeServices {
  static isReady = false;
  static emrService: EmrClient;
  static userService: UserServiceClient;
  // static paymentService: PayClient;
  static appmtsClient: AppmtsClient;
  static scheduleClient: ScheduleApiClient;
  static formsClient: FormsClient;

  constructor(url: string, region: string, idToken: string) {
    InternalBeServices.emrService = new EmrClient({ graphqlUrl: url, region }, idToken);
    InternalBeServices.userService = new UserServiceClient({ graphqlUrl: url, region }, idToken);
    // InternalBeServices.paymentService = new PayClient({ graphqlUrl: url, region }, idToken);
    InternalBeServices.appmtsClient = new AppmtsClient({ graphqlUrl: url, region }, idToken);
    InternalBeServices.scheduleClient = new ScheduleApiClient({ graphqlUrl: url, region }, idToken);
    InternalBeServices.formsClient = new FormsClient({ graphqlUrl: url, region }, idToken);
    InternalBeServices.isReady = true;
  }
}

export class BeServices {
  private static instance: BeServices;
  private constructor() { /* */ }
  public static getInstance(): BeServices {
    if (!BeServices.instance) { BeServices.instance = new BeServices(); }
    return BeServices.instance;
  }
  public get isReady() { return InternalBeServices.isReady; }
  // public get tlmd() { return InternalBeServices.tlmdService; }
  public get user() { return InternalBeServices.userService; }
  public get emr() { return InternalBeServices.emrService; }
  // public get pay() { return InternalBeServices.paymentService; }
  public get appmts() { return InternalBeServices.appmtsClient; }
  public get schedule() { return InternalBeServices.scheduleClient; }
  public get forms() { return InternalBeServices.formsClient; }
}

// #region Actions

export type SupportedLocales = 'fr_CA' | 'en_CA' | 'en_US';
export const kDefaultLocale = 'en_CA';

export enum eAppAuthActions {
  IS_READY, IS_BUSY, APP_IS_BUSY, FROM_SOCIAL, SIGNING_OUT, SET_APP_LOCALE,
  NEEDS_CONFIRM, NEEDS_NEW_PWD, NEEDS_MFA, NEEDS_RESET,
  SET_USR_INFO, SET_CUR_GROUPS, SET_JWT_TOKEN, CLEAR_USR_INFO,
  SET_INFO, CLEAR_INFO,
  SET_ERROR, CLEAR_ERROR,
  //
  ONBOARDING, ONBOARDING_DATA,
}

interface SetIsReadyAction {
  type: typeof eAppAuthActions.IS_READY;
}

interface SetIsBusyAction {
  type: typeof eAppAuthActions.IS_BUSY;
}

interface SetAppIsBusyAction {
  type: typeof eAppAuthActions.APP_IS_BUSY;
  state: boolean;
}

interface FromSocialAction {
  type: typeof eAppAuthActions.FROM_SOCIAL;
}

interface SigningOutAction {
  type: typeof eAppAuthActions.SIGNING_OUT;
  done: boolean;
}

interface SetAppLocaleAction {
  type: typeof eAppAuthActions.SET_APP_LOCALE;
  locale: SupportedLocales;
}

interface SetUserInfoAction {
  type: typeof eAppAuthActions.SET_USR_INFO;
  username?: string;
  cognitoUser: CognitoUser;
  chatInfo?: ChatInfo;
}

interface SetCurGroupsAction {
  type: typeof eAppAuthActions.SET_CUR_GROUPS;
  curGroups?: Group[];
}

interface SetJwtTokenInfoAction {
  type: typeof eAppAuthActions.SET_JWT_TOKEN;
  token: string;
}

interface ClearUserInfoAction {
  type: typeof eAppAuthActions.CLEAR_USR_INFO;
}

interface SetNeedsConfirmAction {
  type: typeof eAppAuthActions.NEEDS_CONFIRM;
  user?: any;
}

interface SetNeedsNewPwdAction {
  type: typeof eAppAuthActions.NEEDS_NEW_PWD;
  user: any;
}

interface SetNeedsMfaAction {
  type: typeof eAppAuthActions.NEEDS_MFA;
  user: any;
}

interface SetNeedsResetAction {
  type: typeof eAppAuthActions.NEEDS_RESET;
  user: any;
}

interface SetErrorAction {
  type: typeof eAppAuthActions.SET_ERROR;
  error: any;
}

interface ClearErrorAction {
  type: typeof eAppAuthActions.CLEAR_ERROR;
}

interface SetInfoAction {
  type: typeof eAppAuthActions.SET_INFO;
  info: any;
}

interface ClearInfoAction {
  type: typeof eAppAuthActions.CLEAR_INFO;
}

interface OnboardingAction {
  type: typeof eAppAuthActions.ONBOARDING;
  ver: string;
  state: OnboardingState;
  json: OnboardingData;
}

interface OnboardingDataAction {
  type: typeof eAppAuthActions.ONBOARDING_DATA;
  onboarding: OnboardingData;
}

export type AppAuthActionTypes = SetIsReadyAction | SetIsBusyAction | SetAppIsBusyAction | FromSocialAction |
  SigningOutAction | SetAppLocaleAction | SetNeedsConfirmAction | SetNeedsNewPwdAction |
  SetNeedsMfaAction | SetNeedsResetAction | SetUserInfoAction | SetCurGroupsAction |
  ClearUserInfoAction | SetErrorAction | ClearErrorAction | SetInfoAction | SetJwtTokenInfoAction | ClearInfoAction |
  OnboardingAction | OnboardingDataAction;

// #endregion Actions

// #region Reducer

export interface IAppAuthState {
  isReady: boolean;
  isBusy: boolean;
  fromSocial: boolean;
  isSignedIn: boolean;
  signingOut: boolean;
  needsConfirm: boolean;
  needsNewPwd: boolean;
  needsMFA: boolean;
  needsReset: boolean;
  username?: string;
  curGroups?: Group[];
  info?: string;
  error?: string;
  user?: AuthUser; // User from AWS at signin
  cognitoUser?: CognitoUser;
  chatInfo?: ChatInfo;
  jwtToken: string;
  appLocale: SupportedLocales;
  //
  onboardingVersion?: string;
  onboardingState?: OnboardingState;
  onboarding?: OnboardingData;
}

const initialState: IAppAuthState = {
  isReady: false,
  isBusy: false,
  fromSocial: false,
  isSignedIn: false,
  signingOut: false,
  needsConfirm: false,
  needsNewPwd: false,
  needsMFA: false,
  needsReset: false,
  jwtToken: '',
  appLocale: i18n.language as SupportedLocales || kDefaultLocale,
}

export interface IAppAuthContext {
  authState: IAppAuthState;
  dispatch: Dispatch<any>;
  refreshToken: () => void;
  hostedUI: () => void;
  setAppIsBusy: (state: boolean) => void;
  signUp: (username: string, password: string, attributes?: SignUpParams['attributes'], validationData?: SignUpParams['validationData'], clientMetadata?: SignUpParams['clientMetadata'], autoSignIn?: SignUpParams['autoSignIn']) => any; // TODO fpaq Add types for returned user...
  confirmSignUp: (username: string, code: string, clientMetadata?: SignUpParams['clientMetadata']) => Promise<boolean>; // TODO fpaq Add types for returned user...
  signIn: (username: string, pwd: string) => any; // TODO fpaq Add types for returned user...
  updPwd: (username: string, pwd: string) => any; // TODO fpaq Add types for returned user...
  sendMFA: (code: string) => any; // TODO fpaq Add types for returned user...
  forgotPwd: (username: string) => any; // TODO fpaq Add types for returned user...
  resetPwd: (username: string, newPwd: string, code: string) => any; // TODO fpaq Add types for returned user...
  cancel: () => any; // TODO fpaq Add types for returned user...
  signOut: () => void;
  startVerifyAttrib: (attrib: VerifiedAttributeType) => any;
  sendVerifyAttribCode: (attrib: VerifiedAttributeType, code: string) => Promise<boolean>;
  updateUserAttrib: (attribs: MoreInfoData) => Promise<boolean>;
  updateLocale: (locale: SupportedLocales) => void;
  clearError: () => void;
  clearInfo: () => void;
  //
  onboarding: (data?: OnboardingData, override?: boolean, clear?: boolean) => any; // TODO fpaq Add types for returned user...
  refreshAuth: () => any; // TODO fpaq Add types for returned user...
  setCurGroups: (curGroups?: Group[]) => any; // TODO fpaq Add types for returned user...
  hasAnyAuthorities: (authorities: Group[]) => boolean;
}

export const authStore = createContext<IAppAuthContext>({
  authState: initialState,
  dispatch: () => null,
  refreshToken: () => { throw new Error('Not implemented.'); },
  hostedUI: () => { throw new Error('Not implemented.'); },
  setAppIsBusy: () => { throw new Error('Not implemented.'); },
  signUp: () => { throw new Error('Not implemented.'); },
  confirmSignUp: () => { throw new Error('Not implemented.'); },
  signIn: () => { throw new Error('Not implemented.'); },
  updPwd: () => { throw new Error('Not implemented.'); },
  sendMFA: () => { throw new Error('Not implemented.'); },
  forgotPwd: () => { throw new Error('Not implemented.'); },
  resetPwd: () => { throw new Error('Not implemented.'); },
  cancel: () => { throw new Error('Not implemented.'); },
  signOut: () => { throw new Error('Not implemented.'); },
  startVerifyAttrib: () => { throw new Error('Not implemented.'); },
  sendVerifyAttribCode: () => { throw new Error('Not implemented.'); },
  updateUserAttrib: () => { throw new Error('Not implemented.'); },
  updateLocale: () => { throw new Error('Not implemented.'); },
  clearError: () => { throw new Error('Not implemented.'); },
  clearInfo: () => { throw new Error('Not implemented.'); },
  //
  onboarding: () => { throw new Error('Not implemented.'); },
  refreshAuth: () => { throw new Error('Not implemented.'); },
  setCurGroups: () => { throw new Error('Not implemented.'); },
  hasAnyAuthorities: () => { throw new Error('Not implemented.'); },
});

function reducer(state: IAppAuthState, action: AppAuthActionTypes) {
  switch (action.type) {
    case eAppAuthActions.IS_READY:
      return { ...state, isReady: true };
    case eAppAuthActions.IS_BUSY:
      return { ...state, isBusy: true };
    case eAppAuthActions.APP_IS_BUSY:
      return { ...state, isBusy: action.state };
    case eAppAuthActions.FROM_SOCIAL:
      return { ...initialState, fromSocial: true };
    case eAppAuthActions.SIGNING_OUT:
      return { ...initialState, isReady: true, signingOut: action.done };
    case eAppAuthActions.SET_APP_LOCALE:
      return { ...state, appLocale: action.locale };
    case eAppAuthActions.NEEDS_CONFIRM:
      return { ...state, isBusy: false, needsConfirm: true, user: action.user };
    case eAppAuthActions.NEEDS_NEW_PWD:
      return { ...state, isBusy: false, needsNewPwd: true, user: action.user };
    case eAppAuthActions.NEEDS_MFA:
      return { ...state, isBusy: false, needsMFA: true, user: action.user };
    case eAppAuthActions.NEEDS_RESET:
      return { ...state, isBusy: false, needsReset: true, user: action.user };
    case eAppAuthActions.SET_USR_INFO: {
      const idToken = action.cognitoUser.getSignInUserSession()?.getIdToken();
      const newState = {
        ...initialState,
        onboardingVersion: state.onboardingVersion,
        onboardingState: state.onboardingState,
        onboarding: { ...state.onboarding },
        isReady: true,
        isSignedIn: true,
        fromSocial: state.fromSocial,
        username: action.username || state.username,
        user: new AuthUser(action.cognitoUser),
        chatInfo: action.chatInfo,
        jwtToken: idToken?.getJwtToken() || '',
        curGroups: idToken?.payload[COGNITO_GROUPS_CLAIMS],
        cognitoUser: action.cognitoUser,
      };
      // console.log('NEW STATE', newState);
      return newState;
    }
    case eAppAuthActions.SET_ERROR:
      return { ...state, isBusy: false, error: action.error };
    case eAppAuthActions.CLEAR_ERROR:
      return { ...state, isBusy: false, error: undefined };
    case eAppAuthActions.SET_INFO:
      return { ...state, isBusy: false, info: action.info };
    case eAppAuthActions.SET_CUR_GROUPS:
      return { ...state, curGroups: action.curGroups };
    case eAppAuthActions.CLEAR_INFO:
      return { ...state, isBusy: false, info: undefined };
    case eAppAuthActions.SET_JWT_TOKEN:
      return { ...state, jwtToken: action.token };
    case eAppAuthActions.CLEAR_USR_INFO:
      return { ...initialState, isReady: true };
    //
    case eAppAuthActions.ONBOARDING:
      return { ...state, onboardingVersion: action.ver, onboardingState: action.state, onboarding: action.json };
    case eAppAuthActions.ONBOARDING_DATA:
      return { ...state, onboarding: action.onboarding };
    default:
      return state;
    // default:
    //   throw new Error(`Unknown action type: ${action}`);
  }
}

// #endregion Reducer

export interface AppAuthPros {
  ignoreOnboarding?: boolean;
  children: React.ReactNode | React.ReactNode[];
}

export function AppAuth(props: AppAuthPros) {
  const { globalState } = useContext(globalStore);
  const [authState, dispatch] = useReducer(reducer, initialState);
  const beServices = useRef<InternalBeServices | null>(null);

  useEffect(() => {
    const remAuthListen = Hub.listen('auth', async ({ payload: { event, data } }: any) => {
      switch (event) {
        // // case 'parsingCallbackUrl':
        // //   break;
        // case 'signIn':
        //   dispatch({
        //     type: eAppAuthActions.SET_USR_INFO,
        //     id: data.username,
        //     jwtToken: data.signInUserSession.idToken.jwtToken,
        //     awsInfo: data.signInUserSession.idToken.payload,
        //   });
        //   break;
        // case 'signIn_failure':
        // case 'cognitoHostedUI_failure':
        // case 'customState_failure':
        //   console.log('signin failure event:', event);
        //   console.log('signin failure data:', data);
        //   break;
        // case 'signOut':
        //   // Always clear awsInfo before user.
        //   dispatch({ type: eAppAuthActions.CLEAR_USR_INFO });
        //   break;
        case 'cognitoHostedUI':
          dispatch({ type: eAppAuthActions.FROM_SOCIAL });
          await bootstrapUser(data);
          break;
        case 'customOAuthState':
          if (globalState.isDev) console.log('>>> HUB CUSTOM DATA:', data)
          break;
        // case 'oAuthSignOut':
        //   dispatch({ type: eAppAuthActions.CLEAR_USR_INFO });
        //   break;
        case 'signIn':
          await bootstrapUser(data);
          break;
        case 'signOut':
          // Always clear awsInfo before user.
          dispatch({ type: eAppAuthActions.CLEAR_USR_INFO });
          break;
        case 'tokenRefresh':
          if (globalState.isDev) {
            console.log('>>> token refreshed @', (new Date()).toLocaleString('fr-CA'));
          }
          break;
        default:
          if (globalState.isDev) console.log('>>> hubCB uncatched:', event, data);
      }
    });

    // hasSavedToken();
    if (!authState.isSignedIn) {
      bootstrapAsync();
    }
    else if (!authState.isReady) {
      dispatch({ type: eAppAuthActions.IS_READY });
    }

    // Remove listener
    return () => remAuthListen();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // if (authState.user && authState.user.locale) {
    //   // Do not do in reducer, will give: Warning: Cannot update a component from inside the function body of a different component.
    //   // i18n.changeLanguage(authState.user.locale.getLangEnum());
    // }
    return () => release();
  }, [authState.user]);

  // #region Functions

  async function refreshToken() {
    try {
      if (globalState.isDev) console.log('>>> REFRESH TOKEN OLD:', authState.jwtToken);
      const currentSession = await Auth.currentSession();
      if (globalState.isDev) console.log('>>> REFRESH TOKEN NEW:', currentSession.getIdToken().getJwtToken());
      dispatch({ type: eAppAuthActions.SET_JWT_TOKEN, token: currentSession.getIdToken().getJwtToken() });
    }
    catch (e) {
      console.log('>>> Unable to refresh Token', e);
    }
  }

  async function hostedUI() {
    await Auth.federatedSignIn();
  }

  function setAppIsBusy(state: boolean) {
    dispatch({ type: eAppAuthActions.APP_IS_BUSY, state });
  }

  async function signUp(
    username: string, password: string, attributes?: SignUpParams['attributes'],
    validationData?: SignUpParams['validationData'], clientMetadata?: SignUpParams['clientMetadata'],
    autoSignIn: SignUpParams['autoSignIn'] = { enabled: true },
  ) {
    if (authState.isBusy) {
      dispatch({ type: eAppAuthActions.SET_ERROR, error: 'Unable to signin, already executing another command.' });
      return;
    }

    dispatch({ type: eAppAuthActions.SET_INFO, info: undefined });
    dispatch({ type: eAppAuthActions.SET_ERROR, error: undefined });
    dispatch({ type: eAppAuthActions.IS_BUSY });

    try {
      const { user, userConfirmed /* , userSub */ } = await Auth.signUp({
        username, password, attributes, validationData, clientMetadata, autoSignIn,
      });
      if (userConfirmed) await bootstrapAsync();
      else dispatch({ type: eAppAuthActions.NEEDS_CONFIRM, user });
    }
    catch (error) {
      const result = (error as any);
      console.log('error signing up:', result);
      if (result.code === 'UsernameExistsException') {
        dispatch({ type: eAppAuthActions.SET_ERROR, error: 'login.messages.error.alreadyExists' });
      }
      else {
        dispatch({ type: eAppAuthActions.SET_ERROR, error: 'error.unexpected' });
      }
    }
  }

  async function confirmSignUp(username: string, code: string, clientMetadata?: SignUpParams['clientMetadata']) {
    try {
      dispatch({ type: eAppAuthActions.IS_BUSY });
      const result = await Auth.confirmSignUp(username, code, { clientMetadata });
      // console.log('>>> AUTH CONFIRM:', typeof result, result)
      if (result === 'SUCCESS') {
        // pointless, user not logged in yet...
        // await bootstrapAsync();
        return true;
      }
      return false;
    }
    catch (error) {
      const result = (error as any);
      // console.log('error confirming sign up', result);
      if (result.code === 'CodeMismatchException') {
        dispatch({ type: eAppAuthActions.SET_ERROR, error: 'login.reset.codeMismatch' });
      }
      else if (
        result.code === 'NotAuthorizedException' &&
        result.message === 'User cannot be confirmed. Current status is CONFIRMED'
      ) {
        // do nothing for now
        return true;
      }
      else {
        dispatch({ type: eAppAuthActions.SET_ERROR, error: 'error.unexpected' });
      }
      return false;
    }
  }

  function signIn(username: string, pwd: string) {
    if (authState.isBusy) {
      dispatch({ type: eAppAuthActions.SET_ERROR, error: 'Unable to signin, already executing another command.' });
      return;
    }

    dispatch({ type: eAppAuthActions.SET_INFO, info: undefined });
    dispatch({ type: eAppAuthActions.SET_ERROR, error: undefined });
    dispatch({ type: eAppAuthActions.IS_BUSY });
    Auth.signIn(username, pwd)
      .then(user => {
        if (user.code === 'NotAuthorizedException' || user.code === 'UserNotFoundException') {
          dispatch({ type: eAppAuthActions.SET_ERROR, error: 'login.messages.error.authentication' });
        }
        else if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
          dispatch({ type: eAppAuthActions.NEEDS_NEW_PWD, user });
        }
        else if (/* user.code === 'UserNotConfirmedException' || */ user.challengeName === 'SMS_MFA') {
          dispatch({ type: eAppAuthActions.NEEDS_MFA, user });
        }
        else {
          bootstrapAsync();
        }
      })
      .catch(error => {
        const result = (error as any);
        // console.log('error signing in:', result);
        if (result.code === 'NotAuthorizedException' || result.code === 'UserNotFoundException') {
          dispatch({ type: eAppAuthActions.SET_ERROR, error: 'login.messages.error.authentication' });
        }
        else if (result.code === 'UserNotConfirmedException') {
          dispatch({ type: eAppAuthActions.NEEDS_CONFIRM });
        }
        else {
          dispatch({ type: eAppAuthActions.SET_ERROR, error: 'error.unexpected' });
        }
      });
  }

  function signOut() {
    dispatch({ type: eAppAuthActions.SIGNING_OUT, done: true });
    Auth.signOut()
      .then(() => {
        dispatch({ type: eAppAuthActions.CLEAR_USR_INFO });
      })
      .catch(error => {
        console.log('SIGNOUT ERROR:', error);
      });
  }

  async function startVerifyAttrib(attrib: VerifiedAttributeType) {
    if (authState.isBusy) {
      dispatch({ type: eAppAuthActions.SET_ERROR, error: 'Unable to start verify, already executing another command.' });
      return;
    }

    dispatch({ type: eAppAuthActions.IS_BUSY });
    try {
      await Auth.verifyUserAttribute(authState.cognitoUser, attrib);
    }
    catch (error) {
      dispatch({ type: eAppAuthActions.SET_ERROR, error: `Unable to ask for ${attrib} verification code` });
    }
  }

  async function sendVerifyAttribCode(attrib: VerifiedAttributeType, code: string) {
    if (authState.isBusy) {
      dispatch({ type: eAppAuthActions.SET_ERROR, error: 'Unable to verifyCode, already executing another command.' });
      return false;
    }

    dispatch({ type: eAppAuthActions.IS_BUSY });
    try {
      await Auth.verifyUserAttributeSubmit(authState.cognitoUser, attrib, code);
      return true;
    }
    catch (error) {
      dispatch({ type: eAppAuthActions.SET_ERROR, error: `Unable to verify for ${attrib} verification code` });
      return false;
    }
  }

  async function updateUserAttrib(attribs: MoreInfoData) {
    if (attribs.mobile == null || !attribs.mobile) return false;

    let result = false;
    try {
      setAppIsBusy(true);
      const awsPhone = awsPhoneNumber(attribs.mobile);
      const d = attribs.birthdate && attribs.birthdate as unknown as Date;
      const bday = d && DateTime.fromJSDate(d, { zone: 'utc' }).toISODate();

      await BeServices.getInstance().user.updateUserAttrib({
        givenName: attribs.givenName,
        familyName: attribs.familyName,
        phoneNumber: awsPhone,
        address: {
          streetAddress: attribs.address.streetAddress,
          locality: attribs.address.locality,
          region: attribs.address.region,
          postalCode: attribs.address.postalCode || '',
          country: attribs.address.country,
        },
        birthdate: bday,
        gender: attribs.gender as Gender,
        locale: LocaleClass.fromString(attribs.locale || Locale.FR_CA).getLocaleEnum(),
        zoneinfo: Intl.DateTimeFormat().resolvedOptions().timeZone || TZ_AMERICA_MONTREAL
      });
      await bootstrapAsync();
      result = true;
    }
    catch (error) {
      dispatch({ type: eAppAuthActions.SET_ERROR, error });
      result = false;
    }
    finally {
      setAppIsBusy(false);
    }

    return result;
  }

  function updateLocale(locale: SupportedLocales) {
    i18n.changeLanguage(locale);
    window.document.documentElement.lang = locale;
    dispatch({ type: eAppAuthActions.SET_APP_LOCALE, locale });
  }

  function updPwd(username: string, pwd: string) {
    if (authState.isBusy) {
      dispatch({ type: eAppAuthActions.SET_ERROR, error: 'Unable to complete new password, already executing another command.' });
      return;
    }

    if (!authState.user) {
      dispatch({ type: eAppAuthActions.SET_ERROR, error: 'Unable to complete new password, missing AWS user.' });
      return;
    }

    Auth.completeNewPassword(authState.user, pwd)
      .then(user => {
        // TODO: add BE call to validate email in Cognito
        // try {
        //   await ApiRequest('PUT', 'adminapi', '/user/cognito', { verifiedEmail: email })
        // }
        // catch (e) {
        //   // TODO: Handle errors...
        // }
        console.log('TODO: Validate username in Cognito:', username);
        if (user.challengeName === 'SMS_MFA') {
          dispatch({ type: eAppAuthActions.NEEDS_MFA, user });
        }
        else {
          bootstrapAsync();
        }
      })
      .catch(error => {
        // console.log('NEW PWD ERROR:', error);
        // TODO: find proper errors from AWS amplify...
        if (error.code === 'NotAuthorizedException' || error.code === 'UserNotFoundException') {
          dispatch({ type: eAppAuthActions.SET_ERROR, error: 'login.messages.error.authentication' });
        }
        else {
          dispatch({ type: eAppAuthActions.SET_ERROR, error: 'error.unexpected' });
        }
      });
  }

  function sendMFA(code: string) {
    if (authState.isBusy) {
      dispatch({ type: eAppAuthActions.SET_ERROR, error: 'Unable to complete new password, already executing another command.' });
      return;
    }

    if (!authState.cognitoUser) {
      dispatch({ type: eAppAuthActions.SET_ERROR, error: 'Unable to complete new password, missing AWS user.' });
      return;
    }

    Auth.confirmSignIn(authState.cognitoUser, code, 'SMS_MFA')
      .then(() => {
        bootstrapAsync();
      })
      .catch(error => {
        // console.log('MFA ERROR:', error);
        // TODO @fp - find proper errors from AWS amplify...
        if (error.code === 'NotAuthorizedException' || error.code === 'UserNotFoundException') {
          dispatch({ type: eAppAuthActions.SET_ERROR, error: 'login.messages.error.authentication' });
        }
        else {
          dispatch({ type: eAppAuthActions.SET_ERROR, error: 'error.unexpected' });
        }
      });
  }

  function forgotPwd(username: string) {
    if (authState.isBusy) {
      dispatch({ type: eAppAuthActions.SET_ERROR, error: 'Unable to complete new password, already executing another command.' });
      return;
    }

    // Send confirmation code to user's email
    Auth.forgotPassword(username)
      .then(user => {
        dispatch({ type: eAppAuthActions.NEEDS_RESET, user: { ...user, phoneNumber: username } });
      })
      .catch(error => {
        // TODO @fp - find proper errors from AWS amplify...
        if (error.code === 'NotAuthorizedException') {
          dispatch({ type: eAppAuthActions.SET_ERROR, error: 'error.http.403' });
        }
        else if (error.code === 'UserNotFoundException') {
          dispatch({ type: eAppAuthActions.SET_ERROR, error: 'login.messages.error.userNotFound' });
        }
        else {
          dispatch({ type: eAppAuthActions.SET_ERROR, error: 'error.unexpected' });
        }
      });
  }

  function resetPwd(username: string, newPwd: string, code: string) {
    if (authState.isBusy) {
      dispatch({ type: eAppAuthActions.SET_ERROR, error: 'Unable to complete new password, already executing another command.' });
      return;
    }

    if (!authState.user) {
      dispatch({ type: eAppAuthActions.SET_ERROR, error: 'Unable to complete new password, missing AWS user.' });
      return;
    }

    // Collect confirmation code and new password, then
    Auth.forgotPasswordSubmit(username, code, newPwd)
      .then(() => {
        signIn(username, newPwd);
      })
      .catch(error => {
        // TODO @fp - find proper errors from AWS amplify...
        if (error.code === 'NotAuthorizedException' || error.code === 'UserNotFoundException') {
          dispatch({ type: eAppAuthActions.SET_ERROR, error: 'login.messages.error.authentication' });
        }
        else if (error.code === 'CodeMismatchException') {
          dispatch({ type: eAppAuthActions.SET_ERROR, error: 'login.reset.codeMismatch' });
        }
        else {
          dispatch({ type: eAppAuthActions.SET_ERROR, error: 'error.unexpected' });
        }
      });
  }

  function cancel() {
    if (authState.isBusy) {
      dispatch({ type: eAppAuthActions.SET_ERROR, error: 'Unable to complete new password, already executing another command.' });
      return;
    }
    dispatch({ type: eAppAuthActions.CLEAR_USR_INFO });
  }

  async function bootstrapAsync() {
    try {
      await bootstrapUser((await Auth.currentAuthenticatedUser()) as CognitoUser);
    }
    catch (error) {
      if (error !== 'The user is not authenticated') console.log('SESSION ERROR:', error);
      dispatch({ type: eAppAuthActions.IS_READY });
    }
  }

  async function bootstrapUser(authUser: CognitoUser) {
    try {
      if (authUser) {
        const sessionUser = (await Auth.currentSession());

        beServices.current = new InternalBeServices(
          conf.aws.aws_appsync_graphqlEndpoint || 'INIT_CONF_ERROR',
          conf.aws.aws_appsync_region, sessionUser.getIdToken().getJwtToken(),
        );

        // TODO: Move to flo lib one day
        const username = authUser.getUsername();
        if (username != null && !props.ignoreOnboarding) {
          const onboarding = (await BeServices.getInstance().user.getOnboardingData());
          if (onboarding != null) {
            dispatch({
              type: eAppAuthActions.ONBOARDING,
              state: onboarding.state,
              ver: onboarding.version,
              json: onboarding.data,
            });

            // // re-caching user here because we had issues with user phone verified not set to true
            // // is we refresh after call to sendVerifyAttribCode, eventhought the phone is verifed,
            // //  because of the cache it stays unverified (unless we logout and log back in)
            // authUser = await Auth.currentAuthenticatedUser({ bypassCache: true }) as CognitoUser;
          }
        }

        // TODO: evaluate if this is too much. Maybe we can optimize this...
        authUser = await Auth.currentAuthenticatedUser({ bypassCache: true }) as CognitoUser;

        dispatch({
          type: eAppAuthActions.SET_USR_INFO,
          username: authUser.getUsername(),
          cognitoUser: authUser
        });

        // arghhh, the cognito interface sucks, only callbacks. hacking for now...
        dispatch({ type: eAppAuthActions.SET_APP_LOCALE, locale: (authUser as any).attributes.locale || 'en_CA' });
      }
    }
    catch (error) {
      console.log('BOOTSTRAP USER ERROR:', error);
      dispatch({ type: eAppAuthActions.IS_READY });
    }
  }

  function release() {
    // Nothing for now...
  }

  // TODO: Move to flo lib one day
  async function onboarding(data?: OnboardingData, override?: boolean, clear?: boolean) {
    let onboarding: any = undefined;
    if (clear) {
      if (authState.isSignedIn) {
        await BeServices.getInstance().user.deleteOnboardingData();
      }
    }
    else if (override) {
      onboarding = data;
      if (authState.isSignedIn) {
        await BeServices.getInstance().user.putOnboardingData(onboarding);
      }
    }
    else {
      onboarding = { ...authState.onboarding, ...data };
      if (authState.isSignedIn) {
        await BeServices.getInstance().user.putOnboardingData(onboarding);
      }
    }
    dispatch({ type: eAppAuthActions.ONBOARDING_DATA, onboarding });
  }

  // TODO: Move to flo lib one day
  async function refreshAuth() {
    await bootstrapAsync();
  }

  function setCurGroups(curGroups?: Group[]) {
    dispatch({ type: eAppAuthActions.SET_CUR_GROUPS, curGroups });
  }

  function hasAnyAuthorities(authorities: Group[]) {
    return authState.isSignedIn && authState.curGroups != null && hasProperGroups(authState.curGroups, authorities);
  }

  function clearError() {
    dispatch({ type: eAppAuthActions.CLEAR_ERROR });
  }

  function clearInfo() {
    dispatch({ type: eAppAuthActions.CLEAR_INFO });
  }

  // #endregion Functions

  return <authStore.Provider value={{
    authState, dispatch, refreshToken, hostedUI, setAppIsBusy, signUp, confirmSignUp, signIn,
    signOut, updPwd, sendMFA, forgotPwd, resetPwd, cancel, sendVerifyAttribCode, startVerifyAttrib,
    updateUserAttrib, updateLocale, clearError, clearInfo,
    //
    onboarding, refreshAuth, setCurGroups, hasAnyAuthorities,
  }}>
    {authState.isReady && props.children}
  </authStore.Provider>
}

// #region Hooks

export function useAuthController() {
  const context = useContext<IAppAuthContext>(authStore);

  if (!context) {
    throw new Error(
      'useAuthController should be used inside the AuthProvider.'
    );
  }

  return context;
}

// #endregion Hooks