
export const SES_EMAIL_SUFFIX = 'simulator.amazonses.com';
export const DISPOSTABLE_EMAIL_SUFFIX = 'dispostable.com';
export const FLO_NET = 'flowrence.net';
export const FLO_CA = 'flowrence.ca';
export const NO_REPLY_EMAIL = `no-reply@${FLO_CA}`;
export const EKSOH_COM = 'eksoh.com';

export const Constants = {
  email: {
    address: {
      SB_EKSOH: 'stephane@eksoh.com',
      FP_EKSOH: 'francois@eksoh.com',
      FP_CHAMBORD: 'chambordstudios@gmail.com',
      SB_YAHOO: 'sbouchard1@yahoo.com',
      SB_GMAIL: 'sbouchard@gmail.com',
      FP_POCKET: 'pocketwarestudios@gmail.com',
      FP_IPHONE: 'iphonefrancois@gmail.com',
      PASSTHROUGH_TO_EMAIL: `flowrence-dispostable@${DISPOSTABLE_EMAIL_SUFFIX}` //TODO move in commons
    },
    PASSTHROUGH_PREFIX: '%test-',
    DEFAULT_PASSTHROUGH_EMAIL_LIST: function (): string[] {
      return [
        this.address.PASSTHROUGH_TO_EMAIL,
        this.address.FP_CHAMBORD,
        this.address.FP_POCKET,
        this.address.FP_IPHONE,
        this.address.SB_YAHOO,
        this.address.SB_GMAIL
      ];
    }
  },
  params: {
    USER_TYPE_POST_CONFIRM_PARAM: 'userType' 
  },
  payment: {
    bambora: {
      testCard: {
        visaApproved: {
          name: 'John Doe',
          cardNumber: '4030000010001234',
          date: { month: '11', year: '26' },
          dateStr: '11 / 26',
          cvc: '123'
        },
        visaDeclined: {
          name: 'John Doe',
          cardNumber: '4003050500040005',
          date: { month: '11', year: '26' },
          dateStr: '11 / 26',
          cvc: '123'
        },
        visaLowAmount: {
          name: 'Visa LowAmount',
          cardNumber: '4504481742333',
          date: { month: '11', year: '26' },
          dateStr: '11 / 26',
          cvc: '123'
        }
      }

    }
  }
}

// export const PASSTHROUGH_TO_EMAIL = `flowrence-dispostable@${DISPOSTABLE_EMAIL_SUFFIX}`;
// export const PASSTHROUGH_PREFIX = '%test-';
// export const EMAIL_EKSOH_SB = 'stephane@eksoh.com';
// export const EMAIL_EKSOH_FP = 'francois@eksoh.com';
// export const EMAIL_IPHONE = 'iphonefrancois@gmail.com';
// export const EMAIL_CHAMBORD = 'chambordstudios@gmail.com';
// export const EMAIL_SB1 ='sbouchard1@yahoo.com';
// export const EMAIL_SBGMAIL ='sbouchard@gmail.com';
// export const EMAIL_POCKET = 'pocketwarestudios@gmail.com';

// temp
// export const DEFAULT_PASSTHROUGH_EMAIL_LIST = [
//   PASSTHROUGH_TO_EMAIL,
//   EMAIL_IPHONE,
//   EMAIL_CHAMBORD,
//   EMAIL_POCKET,
//   EMAIL_SB1,
//   EMAIL_SBGMAIL
// ];

export const DEFAULT_PASSTHROUGH_DOMAIN_LIST = [
  EKSOH_COM,
  // FLO_CA,                          disable because we want to intercept tests users i.e. client@flowrence.ca
  DISPOSTABLE_EMAIL_SUFFIX
];

export const TZ_AMERICA_MONTREAL = 'America/Montreal';
export const TZ_ASIA_BANGKOK = 'Asia/Bangkok';
export const TZ_AMERICA_NEW_YORK = 'America/New_York';

export const AWS_REGION_CA_CENTRAL = 'ca-central-1';


export const DEFAULT_PASSWORD_GENERATOR_EXCLUDE = ',[]{}`:_.<>/\\|^&*()\';"?';

export const COGNITO_PROD_MIN_PASSWORD_LENGTH = 12;
export const COGNITO_TEST_MIN_PASSWORD_LENGTH = 8;

export const DEFAULT_PASSWORD = '!Flow2022';    // TODO put in SSM or secrets manager

export const CUSTOM_PRODUCTS = 'custom:products';
export const CUSTOM_LICENSE = 'custom:licenseNo';
export const CUSTOM_SSN = 'custom:ssn';
export const CUSTOM_ENTERPRISE = 'custom:enterpriseNo';
export const CUSTOM_INSURANCE = 'custom:insuranceNo';
export const CUSTOM_FAMILY_NAME = 'custom:familyName';
export const CUSTOM_GIVEN_NAME = 'custom:givenName';
export const CUSTOM_ID_FLOW = 'custom:idFlow';

export const PHONE_NUMBER_VERIFIED_ATTRIB = 'phone_number_verified';
export const EMAIL_VERIFIED_ATTRIB = 'email_verified';

export const COGNITO_GROUPS_CLAIMS = 'cognito:groups';
export const COGNITO_USERNAME_CLAIMS = 'cognito:username';

export const ONE_DAY_IN_SECONDS = 60 * 60 * 24;
export const ONE_DAY_IN_MILLISECONDS = 1000 * ONE_DAY_IN_SECONDS;
