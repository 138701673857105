import React, { createContext, useReducer, Dispatch } from 'react';
//
import {
  User, TestAppmntTypes,
  ListPreAppmtsQuery,
  ListAppmtsQuery, CreatePreAppmtMutation,
  SearchTimeOffersQuery, AppmntTypeDetail,
  CreatePreAppmtInput, ListAppmtsProfessionalQuery,
  CreateAppmtInput,
} from '@eksoh/flo/model'; // TODO @fp: that a big NO NO !!!
import { montreal } from '@eksoh/shared/common';
import { BeServices} from '../../..';
import { checkForErrors } from './errors';

// #region definitions

const defaultGeoloc = {
  coords: {
    accuracy: 1,
    latitude: montreal.latitude,
    longitude: montreal.longitude,
    altitude: null,
    altitudeAccuracy: null,
    heading: null,
    speed: null,
  },
  timestamp: (new Date()).getTime(),
}

export type ListedAppmtsProf = ListAppmtsProfessionalQuery['listAppmtsProfessional'];
export type AppmtProf = NonNullable<ListAppmtsProfessionalQuery['listAppmtsProfessional']>[number];

export type TmpAppmntTypeDetails = { [key: string]: AppmntTypeDetail; };
export type ListedAppointments = ListAppmtsQuery['listAppmts'];
export type ListedAppointment = NonNullable<ListedAppointments>[number];
export type ListedAppointmentKey = NonNullable<ListedAppointments>[number];
export type ListedPreAppointments = ListPreAppmtsQuery['listPreAppmts'];
export type ListedPreAppointment = NonNullable<ListedPreAppointments>[number];
export type PreAppointment = CreatePreAppmtMutation['createPreAppmt'];
export type OffersList = SearchTimeOffersQuery['searchTimeOffers'];
export type WeekOffers = NonNullable<OffersList>['weeks'];
export type DowOffers = NonNullable<OffersList>['weeks'][number];
export type OfferRange = NonNullable<DowOffers['ranges']>[number];

export enum eNectoStatus {
  INIT = 0, IDLE,
  FETCHING_PRE_APPMTS = 10, CREATING_PRE_APPMT, UPDATING_PRE_APPMT, DELETING_PRE_APPMT,
  FETCHING_TIMEOFFERS = 20,
  PRE_APPMT_SUCCESS = 30, PRE_APPMT_ERROR,
  FETCHING_APPMNTS = 40, CREATING_APPMT, UPDATING_APPMT, DELETING_APPMT,
  APPMT_SUCCESS = 50, APPMT_ERROR,
}

export type NectoInfoSuccess = { type: string, extra?: unknown };
export type NectoInfo = { msg: string, info: NectoInfoSuccess };
export type NectoError = { msg: string, error: unknown };

export type ResetLevels = 'appmts' | 'timeoffers' | 'infos' | 'all' | 'all_wo_infos';

// #endregion definitions

// #region actions

export enum eNectoActions {
  SET_READY, SET_STATUS, SET_GEOLOC, RESET,
  SET_APPMT_DETAILS, SET_APPMTS, SET_PRE_APPMTS, SET_PRE_APPMT, START_PRE_APPMT,
  SET_INFO, SET_ERROR,
  // admin
  SET_USERS, SET_APPMT_TYPE,
}

interface SetReadyAction {
  type: typeof eNectoActions.SET_READY;
}

interface SetStatusAction {
  type: typeof eNectoActions.SET_STATUS;
  status: eNectoStatus;
}

interface SetGeolocAction {
  type: typeof eNectoActions.SET_GEOLOC;
  geoloc: GeolocationPosition;
}

interface ResetSAction {
  type: typeof eNectoActions.RESET;
  level: ResetLevels;
}

interface SetAppmtDetailsAction {
  type: typeof eNectoActions.SET_APPMT_DETAILS;
  appmntDetails: TmpAppmntTypeDetails;
}

interface SetAppmtsAction {
  type: typeof eNectoActions.SET_APPMTS;
  appmts: ListedAppointments | ListedAppmtsProf;
  isHistory?: boolean;
}

interface SetPreAppmtsAction {
  type: typeof eNectoActions.SET_PRE_APPMTS;
  preAppmts: ListedPreAppointments;
}

interface SetPreAppmtAction {
  type: typeof eNectoActions.SET_PRE_APPMT;
  preAppmt: ListedPreAppointment;
}

interface StartPreAppmtsAction {
  type: typeof eNectoActions.START_PRE_APPMT;
  appmtType: TestAppmntTypes;
  preAppmt: PreAppointment;
}

interface SetAppmtTypeAction {
  type: typeof eNectoActions.SET_APPMT_TYPE;
  appmtType?: TestAppmntTypes;
}

interface InfoAction {
  type: typeof eNectoActions.SET_INFO;
  info: NectoInfo;
}

interface ErrorAction {
  type: typeof eNectoActions.SET_ERROR;
  error?: NectoError;
}

// admin

interface SetUsersAction {
  type: typeof eNectoActions.SET_USERS;
  users: User[];
}

export type NectoActionTypes = SetReadyAction | SetStatusAction | SetGeolocAction | ResetSAction |
  SetAppmtDetailsAction | SetAppmtsAction | SetPreAppmtsAction | SetPreAppmtAction | StartPreAppmtsAction |
  InfoAction | ErrorAction |
  // admin
  SetUsersAction | SetAppmtTypeAction;

// #endregion actions

// #region states

export interface INectoState {
  isReady: boolean;
  status: eNectoStatus;
  geoloc: GeolocationPosition;
  appmntDetails: TmpAppmntTypeDetails;
  appmts: ListedAppointments | ListedAppmtsProf;
  preAppmts: ListedPreAppointments;
  history: ListedAppointments | ListedAppmtsProf;
  newAppmtType?: TestAppmntTypes;
  newPreAppmt?: PreAppointment;
  offerList?: OffersList;
  // admin
  users?: User[];
  info?: NectoInfo;
  error?: NectoError;
}

const initialState: INectoState = {
  isReady: false,
  status: eNectoStatus.INIT,
  geoloc: defaultGeoloc,
  appmntDetails: {} as TmpAppmntTypeDetails,
  appmts: [],
  preAppmts: [],
  history: [],
}

export interface INectoContext {
  nectoState: INectoState;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dispatch: Dispatch<any>;
  // setup
  ready: () => void;
  reset: (level: ResetLevels) => void;
  setGeoloc: (geoloc: GeolocationPosition) => void;
  setAppmtDetails: (appmntDetails: TmpAppmntTypeDetails) => void;
  setAppmts: (appmts: ListedAppointments | ListedAppmtsProf, isHistory?: boolean) => void;
  setPreAppmts: (preAppmts: ListedPreAppointments) => void;
  // actions
  startPreAppmt: (input: CreatePreAppmtInput) => void;
  continuePreAppmt: (appmtType: TestAppmntTypes, preAppmt: ListedPreAppointment) => void;
  setAppmtType: (appmtType?: TestAppmntTypes) => void;
  createAppmt: (input: CreateAppmtInput, username?: string, identityId?: string) => void;
  // admin
  setUsers: (users: User[]) => void;
}

// #endregion states

// #region context

export const nectoStore = createContext<INectoContext>({
  nectoState: initialState,
  dispatch: () => null,
  // setup
  ready: () => { throw new Error('Not implemented.'); },
  reset: () => { throw new Error('Not implemented.'); },
  setGeoloc: () => { throw new Error('Not implemented.'); },
  setAppmtDetails: () => { throw new Error('Not implemented.'); },
  setAppmts: () => { throw new Error('Not implemented.'); },
  setPreAppmts: () => { throw new Error('Not implemented.'); },
  // actions
  startPreAppmt: () => { throw new Error('Not implemented.'); },
  continuePreAppmt: () => { throw new Error('Not implemented.'); },
  setAppmtType: () => { throw new Error('Not implemented.'); },
  createAppmt: () => { throw new Error('Not implemented.'); },
  // asmin
  setUsers: () => { throw new Error('Not implemented.'); },
});

function reducer(state: INectoState, action: NectoActionTypes) {
  switch (action.type) {
    // setup
    case eNectoActions.SET_GEOLOC: {
      return { ...state, geoloc: action.geoloc };
    }
    case eNectoActions.SET_APPMT_DETAILS: {
      return { ...state, appmntDetails: action.appmntDetails };
    }
    case eNectoActions.SET_APPMTS: {
      return action.isHistory
        ? { ...state, history: action.appmts }
        : { ...state, appmts: action.appmts, };
    }
    case eNectoActions.SET_PRE_APPMTS: {
      return { ...state, preAppmts: action.preAppmts };
    }
    case eNectoActions.SET_PRE_APPMT: {
      return {
        ...state,
        preAppmts: state.preAppmts?.map(pa => pa.code === action.preAppmt.code && pa.lng === action.preAppmt.lng && pa.lat === action.preAppmt.lat ? action.preAppmt : pa),
      };
    }
    case eNectoActions.START_PRE_APPMT: {
      return { ...state, newPreAppmt: action.preAppmt, newAppmtType: action.appmtType };
    }
    case eNectoActions.SET_APPMT_TYPE: {
      return { ...state, newAppmtType: action.appmtType };
    }
    // status
    case eNectoActions.SET_READY: {
      return { ...state, isReady: true, status: eNectoStatus.IDLE };
    }
    case eNectoActions.RESET: {
      // console.log('>>> RESET NECTO:', action.level)
      switch (action.level) {
        case 'appmts': return { ...state, newAppmtType: undefined, newPreAppmt: undefined };
        case 'timeoffers': return { ...state, users: undefined, offerList: undefined };
        case 'infos': return { ...state, info: undefined, error: undefined };
        case 'all_wo_infos': return {
          ...state, newAppmtType: undefined, newPreAppmt: undefined, offerList: undefined,
        };
        case 'all': return {
          ...state, newAppmtType: undefined, newPreAppmt: undefined, offerList: undefined,
          info: undefined, error: undefined,
        };
        default: return state;
      }
    }
    case eNectoActions.SET_STATUS: {
      return { ...state, status: action.status };
    }
    case eNectoActions.SET_INFO: {
      return { ...state, info: action.info };
    }
    case eNectoActions.SET_ERROR: {
      return { ...state, error: action.error };
    }
    // admin
    case eNectoActions.SET_USERS: {
      return { ...state, users: action.users };
    }
    default:
      return state;
  }
}

// #endregion context

// #region component

export interface NectoContextProps {
  children: React.ReactNode | React.ReactNode[];
}

export function NectoContext({ children }: NectoContextProps) {
  const [nectoState, dispatch] = useReducer(reducer, initialState);

  // #region setup

  function ready() {
    return dispatch({ type: eNectoActions.SET_READY });
  }

  function reset(level: ResetLevels) {
    return dispatch({ type: eNectoActions.RESET, level });
  }

  function setGeoloc(geoloc: GeolocationPosition) {
    return dispatch({ type: eNectoActions.SET_GEOLOC, geoloc });
  }

  function setAppmtDetails(appmntDetails: TmpAppmntTypeDetails) {
    return dispatch({ type: eNectoActions.SET_APPMT_DETAILS, appmntDetails });
  }

  function setAppmts(appmts: ListedAppointments, isHistory?: boolean) {
    return dispatch({ type: eNectoActions.SET_APPMTS, appmts, isHistory });
  }

  function setPreAppmts(preAppmts: ListedPreAppointments) {
    return dispatch({ type: eNectoActions.SET_PRE_APPMTS, preAppmts });
  }

  async function startPreAppmt(input: CreatePreAppmtInput) {
    if (!nectoState.isReady) return;
    dispatch({ type: eNectoActions.SET_STATUS, status: eNectoStatus.CREATING_PRE_APPMT });
    const preAppmt = await BeServices.getInstance().appmts.createPreAppmt(input);
    if (preAppmt != null) dispatch({ type: eNectoActions.START_PRE_APPMT, preAppmt, appmtType: input.appmtType as TestAppmntTypes });
    else dispatch({ type: eNectoActions.SET_ERROR, error: {
      msg: 'unable to create pre appointment',
      error: { type: 'TODO' },
    } });
    dispatch({ type: eNectoActions.SET_STATUS, status: eNectoStatus.IDLE });
  }

  function continuePreAppmt(appmtType: TestAppmntTypes, preAppmt: ListedPreAppointment) {
    dispatch({ type: eNectoActions.START_PRE_APPMT, appmtType, preAppmt });
  }

  async function setAppmtType(appmtType?: TestAppmntTypes) {
    dispatch({ type: eNectoActions.SET_APPMT_TYPE, appmtType });
  }

  /*
  export type AdmCreateAppmtInput = {  ...CreateAppmtInput = {
    appmtType: TestAppmntTypes;             appmtType: TestAppmntTypes;
    detail: {
      email: Scalars['AWSEmail'];           email: Scalars['AWSEmail'];
      familyName: Scalars['String'];        familyName: Scalars['String'];
      givenName: Scalars['String'];         givenName: Scalars['String'];
      identityId: Scalars['ID'];
      locale: Locale;                       locale: Locale;
      phoneNumber?: InputMaybe<['Phone']>;  phoneNumber?: InputMaybe<Scalars['AWSPhone']>;
    };
    lat: Scalars['Float'];                  lat: Scalars['Float'];
    lng: Scalars['Float'];                  lng: Scalars['Float'];
    paymentInfo: PaymentInfoInput;          paymentInfo: PaymentInfoInput;
    price?: InputMaybe<Scalars['Int']>;
    start: Scalars['AWSDateTime'];          start: Scalars['AWSDateTime'];
    tz?: InputMaybe<Scalars['String']>;     tz?: InputMaybe<Scalars['String']>;
    username: Scalars['ID'];
  };                                      };
  */

  async function createAppmt(input: CreateAppmtInput, username?: string, identityId?: string) {
    if (!nectoState.isReady) return;
    dispatch({ type: eNectoActions.SET_STATUS, status: eNectoStatus.CREATING_APPMT });
    try {
      if (username && identityId) {
        checkForErrors(
          dispatch,
          await BeServices.getInstance().appmts.admCreateAppmt({ ...input, username, storageIdentityId: identityId })
        );
      }
      else {
        checkForErrors(
          dispatch,
          await BeServices.getInstance().appmts.createAppmt(input)
        );
      }
    }
    catch (error) {
      dispatch({
        type: eNectoActions.SET_ERROR,
        error: {
          msg: 'Unable to create appointment. If you have tried to book the same appointment at the same time before, we are unable to book your appointment for now. Try another date or time or call Flowrence for assistance.',
          error: { type: 'TODO' },
        },
      });
    }
    dispatch({ type: eNectoActions.SET_STATUS, status: eNectoStatus.IDLE });
  }

  // admin

  function setUsers(users: User[]) {
    return dispatch({ type: eNectoActions.SET_USERS, users });
  }

  // #endregion setup

  return <nectoStore.Provider value={{
    nectoState, dispatch, ready, reset, setGeoloc, setAppmtDetails, setAppmts, setPreAppmts,
    startPreAppmt, continuePreAppmt, createAppmt,
    // admin
    setUsers, setAppmtType,
  }}>
    {children}
  </nectoStore.Provider>
}

// #endregion component
