import { 
  AUTH_TYPE, 
  AuthOptions
} from "aws-appsync-auth-link";
//
// import {
//   CreateSetupIntentInput,
//   CreateSetupIntentDocument,
//   ChargeCustomerInput,
//   ChargeCustomerDocument
// } from '@eksoh/flo/model';
import { ApolloBase } from './apollobase';
import { AppsyncConfigParams } from '@eksoh/shared/common';


export class PayClient extends ApolloBase {
  constructor(params: AppsyncConfigParams, jwtToken: string, tz?: string) {    
    const auth: AuthOptions = {
      type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
      jwtToken: async () => jwtToken
    };
    super(params, auth, tz);
  }

  // async createSetupIntent(input: CreateSetupIntentInput) {
  //   const result = await this.client.mutate({
  //     mutation: CreateSetupIntentDocument,
  //     variables: { input }
  //   });
  //   return result.data?.createSetupIntent;
  // }

  // async chargeCustomer(input: ChargeCustomerInput) {
  //   const result = await this.client.mutate({
  //     mutation: ChargeCustomerDocument,
  //     variables: { input }
  //   });
  //   return result.data?.chargeCustomer;
  // }
}
